<template>
  <div class="w1200 flex_box">
    <div class="contactUs" v-html="content"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content:''
    }
  },
  mounted() {
    this.getCallUs()
  },
  methods:{
    getCallUs() {
      this.$api.getCallUs().then(res=>{
        this.content = res.data.contents
      })
    }
  }
}
</script>

<style>
.contactUs img{
  max-width: 100%
}
</style>